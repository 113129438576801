<template>
  <div>
    <div class="applyHouseCollection">
      <rxNavBar :title="navTitle"></rxNavBar>
      <div class="headerBlock">
        <div :class="{'wait-approve':(detailValid.approvalStatus ==0||detailValid.approvalStatus ==3||detailValid.approvalStatus ==4||detailValid.approvalStatus ==5) ,'statePassed' :  detailValid.approvalStatus ==1 , 'stateRejected' :detailValid.approvalStatus ==2  }">
          <span>{{detailValid.approvalStatusStr}}</span>
        </div>
        <div class="houseAddress">{{detailValid.itemName}}</div>
      </div>

      <div class="displayBlock">
        <table  class="houseInfo">
          <tr>
            <td><div class="boldFont">申请人：<span class="normalFont">{{detailValid.staffName}}</span></div></td>
          </tr>
          <tr>
            <td><div class="boldFont">预计价格：<span class="normalFont"><font color="#F07A66">{{detailValid.price}}</font></span></div></td>
            <td><div class="boldFont">原报价：<span class="normalFont">{{detailValid.originalPrice}}</span></div></td>
          </tr>
          <tr>
            <td><div class="boldFont">底价：<span class="normalFont"><font color="#F07A66">{{detailValid.floorPrice}}</font></span></div></td>
            <td><div class="boldFont">原底价：<span class="normalFont">{{detailValid.originalFloorPrice}}</span></div></td>
          </tr>
          <tr>
            <td colspan="2"><div class="boldFont">变更原因：<span class="normalFont">{{detailValid.modifyReason}}</span></div></td>
          </tr>
        </table>
      </div>

      <div class="panel">
        <van-tabs v-model="active1"
                  line-width="20"
                  line-height="5"
                  title-active-color="rgb(255,81,45)"
                  sticky
                  swipeable
                  offset-top="1.2195121951219512rem"
        >
          <van-tab title="物品规格">
            <div style="box-sizing: border-box;margin: 0.4rem;padding:0.2rem;background-color: white">
              <table>
                <tr>
                  <td><div class="boldFont">配置类型：<span class="normalFont">{{detailValid.itemTypeStr}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">物品分类：<span class="normalFont">{{detailValid.middlesumName}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">物品：<span class="normalFont">{{detailValid.goodsAllocationClassName}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">物品规格：<span class="normalFont">{{detailValid.itemName}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">运送费：<span class="normalFont">{{detailValid.deliveryCost}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">安装费：<span class="normalFont">{{detailValid.installationCost}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">供应商：<span class="normalFont">{{detailValid.supplierName}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">品牌：<span class="normalFont">{{detailValid.brandName}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">质保：<span class="normalFont">{{detailValid.warrantyPeriod}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">是否配货：<span class="normalFont">{{detailValid.isItemConfiguration}}</span></div></td>
                  <td><div class="boldFont">是否业主：<span class="normalFont">{{detailValid.isOwner}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">是否物品：<span class="normalFont">{{detailValid.isgood}}</span></div></td>
                  <td><div class="boldFont">是否允许改价：<span class="normalFont">{{detailValid.modifyUnitPrice}}</span></div></td>
                </tr>
                <tr>
                  <td><div class="boldFont">是否宽带：<span class="normalFont">{{detailValid.isbroadband}}</span></div></td>
                  <td><div class="boldFont">宽带周期：<span class="normalFont" v-if="detailValid.cycle">{{detailValid.cycle}}</span></div></td>
                </tr>
                 <tr>
                  <td><div class="boldFont">是否库存：<span class="normalFont">{{detailValid.isStock}}</span></div></td>
                </tr>
                <tr>
                  <td colspan="2"><div class="boldFont">备注：<span class="normalFont">{{detailValid.remark}}</span></div></td>
                </tr>
              </table>
            </div>

          </van-tab>
          <van-tab title="审批记录">
            <div class="approve" v-if="approveRecordList.length>0">
              <div class="time_line">
                <div class="item" v-for="(item,index) in approveRecordList" :key="index">
                  <div class="left">
                    <div :class="{line:true ,line_top:true,first_top:index==0}"></div>
                    <div class="point"></div>
                    <div class="line line_bottom" v-if="index != approveRecordList.length - 1"></div>
                  </div>
                  <div class="right">
                    <div class="approve_panel">
                      <p class="total_color">
                        {{item.approvalStatusStr}}
                        <span class="floatR small_font">{{item.approvalTime}}</span>
                      </p>
                      <p style="margin: 0px">
                        {{item.staffName}}
                        <span style="display: inline-block;margin-left: 0.2rem" >{{item.approvalOpinion}}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <common-empty v-else></common-empty>
          </van-tab>
        </van-tabs>
      </div>

      <div v-if="enterType=='approve'">
        <div class="normalBlock" >
          <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
          <span class="blockTitle">审核结果</span>
          <div class="rightText">
            <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
            <span class="content-divide" > | </span>
            <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
          </div>
        </div>
        <div class="displayBlock">
          <div><span class="suggestTitle">审批意见</span></div>
          <div><textarea  class="textArea" v-model="approvalOpinion"></textarea></div>
        </div>
        <div>
          <van-button class="saveButton_Disable" :class="{'saveButton_Enable': (isPass) || (isReject && approvalOpinion!='')}" :disabled=" (isReject&&approvalOpinion=='')||enable" @click="save" >保存</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {responseUtil,getStaffId} from "../../../libs/rongxunUtil";
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {
  queryGoodsAllocationApprovalDetail,goodsAllocationApprove
} from "../../../getData/getData";
import {Button, Tab, Tabs} from "vant";
export default {
  name: "goodsChangeApprove",
  components:{
    rxNavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
  },
  data(){
    return{
      navTitle:'物品变更审核',
      active1: 0,
      detailValid:{},
      approveRecordList:[],
      enterType:'',
      isPass:true,
      isReject:false,
      approvalResult:'0',
      approvalOpinion:'',
      enable:false
    }
  },
  created() {
    this.isPass = true
    this.isReject = false
    this.approvalResult = '0'
    this.approvalOpinion = ''
    this.enterType = this.$route.query.enterType
    this.queryGoodsAllocationApprovalDetail()
  },
  methods:{
    queryGoodsAllocationApprovalDetail(){
      let that = this
      let initData = {}
      initData.goodsAllocationApproval_id = this.$route.query.id
      queryGoodsAllocationApprovalDetail(initData).then(function (response){
        responseUtil.dealResponse(that, response, () => {
          that.detailValid = response.data.data.data
          that.detailValid.price = response.data.data.data.price
          that.detailValid.floorPrice = response.data.data.data.floorPrice!=undefined?response.data.data.data.floorPrice:''
          that.detailValid.deliveryCost = response.data.data.data.deliveryCost!=undefined?response.data.data.data.deliveryCost:''
          that.detailValid.installationCost = response.data.data.data.installationCost!=undefined?response.data.data.data.installationCost:''
          that.detailValid.isbroadband = response.data.data.data.isbroadband==0?'否':'是'  //宽带
          that.detailValid.isItemConfiguration = response.data.data.data.isItemConfiguration==0?'否':'是'  //配货
          that.detailValid.isOwner = response.data.data.data.isOwner==0?'否':'是'  //业主
          that.detailValid.isgood = response.data.data.data.isgood=='0'?'否':'是'  //物品
          that.detailValid.isStock = response.data.data.data.isStock=='1'?'否':'是'  //库存
          that.detailValid.modifyUnitPrice = response.data.data.data.modifyUnitPrice?'是':'否'  //改价
          that.detailValid.srcList = []
          that.detailValid.srcList.push(response.data.data.data.path)
          if(response.data.data.approvalData!=undefined){
            that.approveRecordList = response.data.data.approvalData
          }else{
            that.approveRecordList = []
          }
        })
      })
    },
    choosePass(){
      this.isPass = true;
      this.isReject = false;
      this.approvalResult = '0'
    },
    chooseReject(){
      this.isReject = true;
      this.isPass = false;
      this.approvalResult = '1'
    },
    save(){
      let that = this;
      let initData = {}
      initData.staff_id = getStaffId()
      initData.goodsAllocationApproval_id = this.$route.query.id
      initData.approval_id = this.$route.query.approval_id
      initData.approvalResult = this.approvalResult
      initData.approvalOpinion = this.approvalOpinion
      that.enable = true
      goodsAllocationApprove(initData).then(function (response) {
        that.enable = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that,response.data.msg)
          that.$router.go(-1);
        })
      })
    },
  },
}
</script>

<style scoped>

</style>

<style lang="less">

.applyHouseCollection{
  overflow: hidden;
}
// 图片墙
.swiper_show{
  width: 9rem;
  position: relative;
  margin: 8px 13px;
  height: 80px;
  .content{
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;
    .imgWrap{
      margin-right: 10px;

    }
  }
  .num{
    position: absolute;
    left: 8px;
    top: 17px;
    font-size: 10px;
    background-color: rgb(0,0,0);
    opacity: 0.2;
    color: rgb(255,255,255);
    padding:4px 6px 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: bolder;
    line-height: 10px;
  }

}
.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  border-radius: 8px;
}
.part-inputpart-dropDownList{
  width: 90%;
  position: absolute;
  left: 19px;
  z-index: 99;
  margin-top: -65px;

}
.part-inputpart-dropDownList-option{
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 30px;
  background-color: #f5f5f5;
  font-size: 12px;
  border-bottom: 1px white solid;
  color: #999999;
}
.part-inputpart-dropDownList-option-selected{
  color: #ff5d3b;
}
.part-inputpart-row-right-upArrow{
  width: 9px;
  transform: rotateX(180deg);
}
.part-inputpart-row-header{
  font-weight: bold;
  font-size: 14px;
  width: 13%;
}
.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
  font-size: 14px;
}
.part-inputpart-row-normaltext{
  color: black;
}
.part-inputpart-row-right{
  float: right;
  margin-right: 10px;
}
.part-inputpart-row-right-downArrow{
  width: 9px;
}
.part-inputpart-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  background-color: white;
  border-radius: 9px;
  width:9rem;
  margin: 19px auto;
}
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin:0 10px;
}
.content-divide-small {
  font-size: 14px;
  // color: #9a9a9a;
  margin:0 5px;
}
.selectReason{
  width: 245px;
  height: 1rem;
  line-height: 28px;
  margin-left: 20px;
  font-size: 14px;
  border-style: none;
}
.suggestTitle{
  margin-left: 22px;
  margin-top:12px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.textArea{
  resize: none;
  padding: 10px;
  margin:43px auto 15px;
  height: 120px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: rgba(182, 177, 177, 0.11);
}
.priceUnit{
  color: rgba(153, 153, 153, 0.45);
  font-size: 14px;
  float: right;
  padding-right: 31px;
}
.inputPrice{
  color:#ff3c00;
  width: 100%;
  height: 28px;
  line-height: 28px;
  font-size: 15px;
  //font-weight: bold;
  border: none;
}
.divide{
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}
.passText{
  flex: 1;
  padding-right: 7px;
}
.rejectText{
  padding-left: 7px;
  padding-right: 33px;

}
.rightText{
  margin-left:auto;
  font-size: 14px;
  color: #999999;
}
.maker{
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}
.wait-approve,.statePassed,.stateRejected{
  width: 70px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
  color: white;
  border-radius: 8px 0;
}
/*列表状态样式 1是未审批*/
.wait-approve{
  background: -webkit-linear-gradient(left,#FFC274,#FF5D3B);
}
/*列表状态样式 2是已通过*/
.statePassed{
  background: -webkit-linear-gradient(left,#66CB94,#28D3B0);
}
/*列表状态样式 3是已驳回*/
.stateRejected{
  background: -webkit-linear-gradient(left,#E02020,#FF431B);
}
.orangeColor{
  color: #FE5E3D;
}
.choosedStyle{
  color: #FC5D40;
  font-weight: bold;
}
.normalFont{
  font-size: 13px;
  font-weight: normal;
  color: #767676;
}
.blockTitle{
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}
.boldFont{
  font-size: 14px;
  font-weight: bold;
  //width: 92%;
}
.descTitle{
  height: 25px;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
}
.descText{
  height: auto;
  padding-left: 20px;
  padding-bottom: 14px;
  padding-right: 20px;
  line-height: 23px;
}
.houseInfo{
  box-sizing: border-box;
  padding: 14px 20px;
  //padding-bottom: 14px;
  ////width:9.2rem;
  //padding-left: 20px;

}
.applyTime{
  font-size: 12px;
  text-align: right;
  color: #7d7b7b;
  padding-right: 15px;
  margin-top: 16px;
  margin-bottom: 6px;
}
.houseAddress{
  font-size: 16px;
  font-weight: bold;
  height: auto;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 10px;
}
.keyAddress{
  font-size: 14px;
  height: auto;
  padding-top: 7px;
  padding-bottom: 17px;
  padding-left: 15px;
  color: #7d7b7b;

}
.headerBlock{
  background-color: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width:9rem;
  height: 70px;
  margin: 25px auto 19px;
  margin-bottom: 2px;
}

.nav-bar{
  background-color: #f8f8f8;
  border: none;
  font-weight: bold;
}

.saveButton_Disable{
  display: block;
  background-color: rgba(184, 184, 184, 0.2);
  width: 90%;
  height: 1.22rem;
  border-radius: 8px;
  margin:30px 19px 50px;
  font-size: 18px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
}
.saveButton_Enable{
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

}
.middle{
  overflow:hidden;
  height: auto;
}
.displayBlock {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 0 0 8px 8px;
  width:90%;
  height: auto;
  margin: 1px auto;
}
//.thing{
//  color: #F17256;
//  font-size: 13px;
//  font-weight: bold;
//  margin-top: 30px;
//  margin-left: 19px;
//}
.rejected{
  color: #B8B8B8;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 33px;
  margin-bottom: 20px;
}
.listtop {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  width:9rem;
  height: auto;
  /*margin-top: 20px ;*/
  margin: -5px auto 0px;
}
.list {
  overflow: hidden;
  position: relative;
  background-color: white;
  width:9rem;
  height: auto;
  /*margin-top: 2px;*/
  margin: 3px auto 0px;
}
.listBottoom {
  overflow: hidden;
  position: relative;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  width:9rem;
  height: auto;
  margin: 3px auto;
}
.normalBlock{
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width:9rem;
  line-height: 1rem;
  height: 50px;
  margin: 19px auto;
}

/*物品清单参数*/
.review{//参数
  margin:  1px 15px 15px;
  height: auto;
  background-color: white;

  overflow: hidden;
}
.panel .review:first-of-type{
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.panel .review:last-of-type{
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.panelInfo{//参数
  padding: 5px 14px;
  font-size: 14px;
  p{
    font-weight: bolder;
    margin: 15px 0px;
    .fitment{
      color: #f00;
    }
    span{
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }
  }
}

// 时间线
.approve {
  margin-top: 20px;

  .time_line {
    font-size: 12px;
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 100%;
        padding-bottom: 20px;
        .total_color {
          color: rgba(255, 93, 59, 1) !important;
          margin: 0px;
        }
        .floatR {
          float: right !important;
        }
        .small_font {
          font-size: 12px !important;
        }
        .approve_panel {
          margin: 0 15px;
          border-radius: 8px;
          background-color: rgb(255, 255, 255);
          padding: 10px;

          p {
            padding-top: 10px;
            font-weight: bolder;

            &:last-of-type {
              padding-bottom: 10px;
            }

            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }
          }
        }
      }
    }

  }
}
</style>